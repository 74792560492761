import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AddressModel, ProfileModel} from 'src/app/swagger';
import { Role } from '../../../common/enums/role.enum';
import { UserProfileService } from '../../../common/services/user-profile/user-profile.service';
import { Router } from '@angular/router';
import { MenuItem, SideMenuService} from 'src/app/common/services/side-menu/side-menu.service';
import { v4 as uuidv4 } from 'uuid';
import { UserInfoComponent } from 'src/app/customer/components/user-info/user-info.component';

interface RoleItem {
  id: string;
  name: string;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePage implements OnInit {
  @ViewChild('map')
  mapRef: ElementRef<HTMLElement>;

  public userProfile: ProfileModel | null = null;
  public roles: RoleItem[] = [];
  public isAdmin = false;
  public isRestoAdmin = false;
  public isCustomer = false;
  public addressID: string | null = null;

  constructor(
    private userProfileService: UserProfileService,
    private modalController: ModalController,
    private router: Router,
    private sideMenuService: SideMenuService
  ) {
    this.setRoles();
  }

  public sideMenuItems: MenuItem[];

  public generateAddressId() {
    return uuidv4();
  }

  ionViewWillEnter() {
    this.loadSidebarMenus();
  }

  public ngOnInit() {
    this.init();
  }

  private init() {
    this.addressID = this.generateAddressId();
    this.setUserProfile();
  }

  private setRoles() {
    const roles = Object.keys(Role);
    this.roles = roles.map((role) => ({
      id: Role[role],
      name: role,
    }));
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {

        this.userProfile = userProfile;
        this.isAdmin = userProfile?.roles.includes(Role.ADMINS);
        this.isRestoAdmin = userProfile?.roles.includes(Role.RESTO_ADMINS);
        this.isCustomer = userProfile?.roles.includes(Role.USERS);
        this.loadSidebarMenus();
      },
    });
  }

  private loadSidebarMenus() {
    if(this.isAdmin){
      this.sideMenuItems = require('src/app/admin/pages/admin/admin.constant').sideMenuItems;
    } else if (this.isRestoAdmin){
     this.sideMenuItems = require('src/app/resto-admin/pages/resto-admin/resto-admin.constant').sideMenuItems;
    } else if (this.isCustomer){
      this.sideMenuItems = require('src/app/customer/pages/customer/customer.constant').sideMenuItems;
    }
    this.sideMenuService.setMenuItems(this.sideMenuItems);
  }

  public onCancelClick() {
    if (this.userProfile.roles[0] === 'Users') {
      this.router.navigateByUrl('/customer/home');
    } else if (this.userProfile.roles[0] === 'RestaurantAdmins') {
      this.router.navigateByUrl('/resto-admin/home');
    } else if (this.userProfile.roles[0] === 'Admins') {
      this.router.navigateByUrl('/admin/home');
    }
  }

  // public onRouteActivation(loadedCompInstance: object) {
  //   const pageName = loadedCompInstance.constructor.name;
  //   this.sideMenuService.setSelectedMenuItemIndexByPageName(pageName);
  // }

  public handleEditProfile() {
    let self = this;
    self.openUserInfoModal('Edit Your Profile', this.userProfile, 'PROFILE');
  }

  public handleAddAdress() {
    let self = this;
    self.openUserInfoModal('Add New Address', this.userProfile, 'ADDRESS');
  }

  public handleEditAddress(address: AddressModel) {
    let self = this;
    self.openUserInfoModal(
      'Edit Your Address',
      this.userProfile,
      'ADDRESS',
      address
    );
  }

  public handleViewAddress(address: AddressModel) {

    let self = this;
    self.openUserInfoModal(
      'View Address Details',
      this.userProfile,
      'OTHER',
      address,
      true
    );
  }

  public async openUserInfoModal(
    modalTitle?: string,
    userdata?: ProfileModel,
    formType?: string,
    address?: AddressModel,
    isViewAddress?: boolean
  ) {
    const modal = await this.modalController.create({
      id: UserInfoComponent.id,
      component: UserInfoComponent,
      componentProps: {
        modalTitle: modalTitle,
        userProfile: userdata,
        formType: formType,
        editAddress: address,
        isViewAddress: isViewAddress,
      },
    });
    modal.onDidDismiss().then((res) => {
      if (!res || !res.data) {
        return;
      }
    });
    return await modal.present();
  }

  public handleRemoveAddress(address: AddressModel) {
  }
}
