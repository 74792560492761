import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { MenuEntity, MenuItemMapRestControllerService, MenuRestControllerService, MenuUsersRestControllerService, SmartDineResponseLong, SmartDineResponseMenuEntity, SmartDineResponseMenuModel } from 'src/app/swagger';
import { SdEvent } from '../../interfaces/event';
import { MenuNavService } from '../../services/menu-nav/menu-nav.service';
import { MenuUpsertionComponent } from '../menu-upsertion/menu-upsertion.component';
import { RestaurantsService } from '../../services/restaurants/restaurants.service';

interface Menu extends MenuEntity {
  noOfChildItems?: number;
}

@Component({
  selector: 'app-popover',
  template: `
    <ion-list>
      <ion-item button lines="none" (click)="onItemClick($event, 'delete')">
        <ion-icon slot="start" name="trash-outline"></ion-icon>
        <ion-label>Delete</ion-label>
      </ion-item>
    </ion-list>
  `,
  styles: [
    `
    ion-icon[slot="start"] {
      margin-inline-end: 16px;
    }
    `
  ]
})
class PopoverComponent {
  constructor(
    private popoverController: PopoverController,
  ) { }

  public onItemClick(_: MouseEvent, itemClicked: string) {
    this.popoverController.dismiss({ itemClicked });
  }
}

@Component({
  selector: 'app-sd-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
})
export class MenusComponent implements OnInit, OnChanges {

  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  @Input()
  public tableId: string | null = null;

  public menus: Menu[] = [];
  public filteredMenuItems: Menu[] = [];

  @Input() enableToolbar = true;

  public forCustomer = false;

  @Output() sdChange = new EventEmitter<SdEvent>();

  private selectedIndex = -1;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private menuRestControllerService: MenuRestControllerService,
    private menuItemMapRestControllerService: MenuItemMapRestControllerService,
    private menuUsersRestControllerService: MenuUsersRestControllerService,
    private popoverController: PopoverController,
    private alertController: AlertController,
    private menuNavService: MenuNavService,
    private restaurantService: RestaurantsService
  ) {
    const currentUrl = this.router.routerState.snapshot.url;
    this.forCustomer = currentUrl.startsWith('/customer');
  }

  public ngOnInit(): void {
    if(!this.restaurantId) {
      this.restaurantService.getSelectedRestaurantId().subscribe(restaurant => this.restaurantId = restaurant);
    }

    if(!this.restaurantBranchId) {
      this.restaurantService.getSelectedBranchId().subscribe(branch => this.restaurantBranchId = branch);
    }
    this.init();

   }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes['restaurantId']?.currentValue || changes['restaurantBranchId']?.currentValue){
      this.init();
    }

  }

  public onMenuClick(menu: MenuEntity) {
    this.menuNavService.push(menu);
    const extras: NavigationExtras = { queryParams: {} };
    if (this.restaurantId) {
      extras.queryParams.restaurantId = this.restaurantId;
    }
    if (this.restaurantBranchId) {
      extras.queryParams.restaurantBranchId = this.restaurantBranchId;
    }
    if (this.tableId) {
      extras.queryParams.tableId = this.tableId;
    }
    const currentUrl = this.router.routerState.snapshot.url;
    const baseUrl = currentUrl.match(/(.*)menus/g)[0];
    this.router.navigate([baseUrl, menu.menuId], extras);
  }

  public onNewClick() {
    this.showMenuCreationPopup();
  }

  public onEditClick(event: MouseEvent, menu: MenuEntity) {
    event.stopPropagation();
    this.showMenuCreationPopup(menu);
  }

  public onDeleteClick(event: MouseEvent, _: MenuEntity, index: number) {
    event.stopPropagation();
    this.selectedIndex = index;
    this.onDelete();
  }

  public async onMoreClick(event: MouseEvent, _: MenuEntity, index: number) {
    event.stopPropagation();
    this.selectedIndex = index;
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event,
      translucent: true
    });
    popover.onDidDismiss().then((e: { data: { itemClicked: string } }) => {
      if (!e.data) { return; }
      const itemClicked = e.data.itemClicked;
      if (itemClicked === 'edit') {
        // TODO
      } else if (itemClicked === 'delete') {
        // this.onDelete(); // TODO
      }
    });
    return await popover.present();
  }

  public async onDelete() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: `Please confirm the deletion of below mentioned menu.<br><b>${this.menus[this.selectedIndex].name}<b>`,
      buttons: [
        'Cancel',
        {
          text: 'Delete',
          handler: () => {
            this.deleteMenu();
          }
        }
      ]
    });
    await alert.present();
  }

  public init() {
    if (this.forCustomer && this.menuId) {
      this.setSubMenusForCustomer();
    } else if (this.forCustomer) {
      this.setMenusForCustomer();
    } else {
      this.setMenus();
    }
  }

  public async showMenuCreationPopup(menu: MenuEntity = null) {
    let menuId = null;
    if (menu) {
      menuId = menu.menuId;
    }

    const modal = await this.modalController.create({
      id: MenuUpsertionComponent.id,
      component: MenuUpsertionComponent,
      cssClass: 'my-custom-modal-css',
      componentProps: {
        menuId,
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        parentMenuId: this.menuId,
      }
    });
    modal.onDidDismiss().then((res) => {
      if (!res || !res.data) { return; }
      if (res.data.createdMenu) {
        this.menus.push(res.data.createdMenu);
      }
      if (res.data.updatedMenu) {
        const updatedMenu = res.data.updatedMenu as MenuEntity;
        const index = this.menus.findIndex((m) => m.menuId === updatedMenu.menuId);
        this.menus[index] = updatedMenu;
      }
      this.sdChange.emit({ items: this.menus });
    });
    await modal.present();
  }

  private setMenus() {
    this.menus = [];
    this.sdChange.emit({ items: this.menus });
    this.menuRestControllerService.getMenus(this.restaurantId, this.restaurantBranchId, this.menuId).subscribe({
      next: (menus: SmartDineResponseMenuEntity) => {
        this.menus = menus.items as Menu[];
        this.filteredMenuItems = this.menus;
        this.menus.forEach(element => {
          this.setMenuItemCount(element);
        });
        this.sdChange.emit({ items: this.menus });
      },
      error: (err) => {
        console.error('Unable to get menus!', err);
      }
    });
  }

  private setMenusForCustomer() {
    this.menus = [];
    this.sdChange.emit({ items: this.menus });
    this.menuUsersRestControllerService.getTopLevelMenus(this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuModel) => {
        this.menus = res.items.map((each) => {
          return {
            menuId: each.menuId,
            name: each.menuName,
            description: each.menuDescription,
            orderable: each.orderable,
            orderableFromTime: each.orderableFromTime,
            orderableToTime: each.orderableToTime,
            menuIconImage: each.menuIconImage,
            menuImages: each.menuImages,
            noOfChildMenus: each.noOfChildMenus,
          } as Menu;
        });
        this.menus.forEach(element => {
          if (!this.forCustomer) { // TODO: Needs Removal
            this.setMenuItemCount(element);
          }
        });
        this.sdChange.emit({ items: this.menus });
      },
      error: (err) => {
        console.error('Unable to get menus for customer!', err);
      }
    });
  }

  private setSubMenusForCustomer() {
    this.menus = [];
    this.sdChange.emit({ items: this.menus });
    this.menuUsersRestControllerService.getSubMenus(this.restaurantId, this.restaurantBranchId, this.menuId).subscribe({
      next: (res: SmartDineResponseMenuModel) => {
        this.menus = res.items.map((each) => {
          return {
            menuId: each.menuId,
            name: each.menuName,
            description: each.menuDescription,
            orderable: each.orderable,
            orderableFromTime: each.orderableFromTime,
            orderableToTime: each.orderableToTime,
            menuIconImage: each.menuIconImage,
            menuImages: each.menuImages,
            noOfChildMenus: each.noOfChildMenus,
          } as Menu;
        });
        this.menus.forEach(element => {
          if (!this.forCustomer) { // TODO: Needs Removal
            this.setMenuItemCount(element);
          }
        });
        this.sdChange.emit({ items: this.menus });
      },
      error: (err) => {
        console.error('Unable to get sub menus for customer!', err);
      }
    })
  }

  private deleteMenu() {
    const menu = this.menus[this.selectedIndex];
    this.menuRestControllerService.deleteMenu(menu.menuId, menu.restaurantId, menu.restaurantBranchId).subscribe(
      (_: SmartDineResponseMenuEntity) => {
        this.menus.splice(this.selectedIndex, 1);
      },
      (err) => {
        console.error('Unable to delete menu!', err);
      }
    );
  }

  private setMenuItemCount(menu: Menu) {
    this.menuItemMapRestControllerService.getMenuItemsCount(menu.menuId, menu.restaurantId, menu.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseLong) => {
        menu.noOfChildItems = res.item;
      },
      error: (err) => {
        console.error('Unable to get item count for menu with id -', menu.menuId, err);
      }
    });
  }

  public filterMenus(searchValue: string) {
    if(searchValue === "" || !searchValue.trim()){
      this.setMenus();
    }
    this.menus = this.filteredMenuItems.filter(item =>{
      const lowerSearchValue = searchValue.toLowerCase();
      return (
        item.name.toLowerCase().includes(lowerSearchValue) ||
        item.tags.some(tag => tag.toLowerCase().includes(lowerSearchValue))
      );
    });
  }

}
