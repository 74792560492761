import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/common/services/message/message.service';
import { QrScannerService, QrSNotification } from 'src/app/common/services/qr-scanner/qr-scanner.service';
import { QrScannerComponent } from 'src/app/common/components/qr-scanner/qr-scanner.component';
import { ModalController } from '@ionic/angular';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import { CartService } from 'src/app/common/services/cart/cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  constructor(private qrScannerService: QrScannerService, 
    private modalController: ModalController, 
    private cartService: CartService,
    public modalService: ModalService,
    private router: Router,
    ) { }

  ngOnInit() {
    this.registerForQrNotifications();
  }

  public onScanClick() {
    this.qrScannerService.notify({ action: 'open-scanner' });
  }

  private registerForQrNotifications() {
    this.qrScannerService.getNotifications().subscribe({
      next: (notification: QrSNotification) => {
        if (notification.action === 'open-scanner') {
          this.showQrScannerPopup();
        }
      }
    });
  }

  private async showQrScannerPopup() {
    const modal = await this.modalController.create({
      id: QrScannerComponent.id,
      component: QrScannerComponent,
    });
    this.modalService.changeCurrentModalId(QrScannerComponent.id);
    modal.onDidDismiss().then((res) => {
      this.modalService.changeCurrentModalId(null);
      const qrCode = res?.data?.value || '';
    });
    await modal.present();
  }
  
  public navigateToHome(){
    this.router.navigateByUrl('/customer/home', {state:{value:true}});
  }

}
