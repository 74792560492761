import { Inject, Injectable, Optional } from '@angular/core';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { BASE_PATH, Configuration, iOrderDetailDto } from 'src/app/swagger';
import { MessageService } from '../message/message.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { WebSoundService } from '../web-sound/web-sound.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  @Inject(BASE_PATH) basePath: string;

  private stompClient: any;
  private stompClientConnected = false;

  private notificationCountSubject = new BehaviorSubject<number>(0);
  private notificationList: any[] = [];
  private notificationSubject = new BehaviorSubject<any[]>([]);
  public notification$ = this.notificationSubject.asObservable();
  public notificationCount$ = this.notificationCountSubject.asObservable();

  private subscriptions: { [key: string]: any } = {};

  constructor(
    @Optional() @Inject(BASE_PATH) basePath: string, 
    private messageService: MessageService, 
    configuration: Configuration,
    private soundService: WebSoundService
  ) {    
    const accessToken = typeof configuration.accessToken === 'function'
      ? configuration.accessToken()
      : configuration.accessToken;
  
    this.initializeWebSocket(basePath, accessToken);
  }

  private initializeWebSocket(basePath: string, accessToken: string) {
    const reconnectInterval = 3000; 
    
    const connect = () => {
      if (this.stompClientConnected) {
        console.warn('Already connected to WebSocket.');
        return; 
      }
  
      this.stompClient = Stomp.over(new SockJS(basePath + '/notifications'));
      this.stompClient.connect({ "X-Authorization": "Bearer " + accessToken }, (frame) => {

        this.stompClientConnected = true;  
      }, (error: any) => {
        console.error('WebSocket connection error: ', error);
        //setTimeout(connect, reconnectInterval);
      });
    };
  
    connect();
  }
  
  public subscribeToNewOrders(restaurantId: string, restaurantBranchId: string) {
    const topic = `/topic/neworders/${restaurantId}/${restaurantBranchId}`;
    this.unsubscribeFromTopic(topic);
  
    if (this.stompClientConnected) {
      this.subscriptions[topic] = this.stompClient.subscribe(topic, (messageOutput) => {
        const message = JSON.parse(messageOutput.body);
        console.warn('New order message from server ws:', message);
  
        // Add the notification and play sound for the admin
        this.addNotification({ type: 'newOrder', ...message });
        this.messageService.showMessage('New order is created');
        this.playSound();  // Play sound notification for admin
      });
    } else {
      console.warn('STOMP client is not connected.');
    }
  }
  
  public subscribeToOrderStatusChanges(orderId:string, orderNumber: number) {
    this.unsubscribeFromTopic('/topic/orderstatus/' + orderNumber);
    if (this.stompClientConnected) {
      this.subscriptions['orderStatus'] = this.stompClient.subscribe('/topic/orderstatus/' + orderNumber, (messageOutput) => {
        const message = JSON.parse(messageOutput.body);
        this.addNotification({orderId:orderId, type: 'orderStatus', ...message });
        this.messageService.showMessage('Order status updated');
      });
    } else {
      console.warn('STOMP client is not connected.');
    }
  }

  private unsubscribeFromTopic(topic: string) {
    if (this.subscriptions[topic]) {
      this.subscriptions[topic].unsubscribe();
      delete this.subscriptions[topic];
    }
  }

  public addNotification(notification: {orderId?:string, type: string; orderNumber: number; status: string }) {
    this.notificationList.unshift(notification);
    this.notificationSubject.next(this.notificationList);
    this.incrementNotificationCount();
  }

  public playSound(duration: number = 1500, frequency: number = 1500, volume: number = 1) {
    this.soundService.playBell(duration, frequency, volume);
  }

  public getNotifications() {
    return this.notificationList;
  }
  
  private incrementNotificationCount() {
    const currentCount = this.notificationCountSubject.getValue();
    this.notificationCountSubject.next(currentCount + 1);
  }

  public resetNotificationCount() {
    this.notificationCountSubject.next(0);
  }

  public getNotificationCount() {
    return this.notificationCountSubject.getValue();
  }

  public emitNewOrderNotification(order: iOrderDetailDto ) {
    if (this.stompClientConnected) {
      this.stompClient.send(
        `/topic/neworders/${order.restaurantId}/${order.restaurantBranchId}`,
        {},
        JSON.stringify(order)
      );
    } else {
      console.warn('Unable to emit new order notification - STOMP client is not connected.');
    }
  }
  
}
