import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProfileModel, RestaurantBranchEntity, RestaurantEntity } from 'src/app/swagger';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { Role } from '../../enums/role.enum';
import { RestaurantsService } from '../../services/restaurants/restaurants.service';

export interface FiltersValue {
  restaurantId: string;
  restaurantBranchId: string;
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {

  @Output()
  public restaurantIdChange = new EventEmitter<string>();

  @Output()
  public restaurantBranchIdChange = new EventEmitter<string>();

  @Output()
  public filtersValueChange = new EventEmitter<FiltersValue>();

  public isAdmin = false;

  public isRestaurantAdmin = false;

  public isCustomer = false;

  private userProfile: ProfileModel | null = null;

  public restaurants: RestaurantEntity[] = [];

  public branches: RestaurantBranchEntity[] = [];

  public restaurantId: string | null = null;

  public restaurantBranchId: string | null = null;

  constructor(
    private userProfileService: UserProfileService,
    private restaurantsService: RestaurantsService,
  ) { }

  public onRestaurantChange(event: Event) {
    const e = event as CustomEvent;
    const isTrusted = (e?.currentTarget as any)?.classList?.contains('select-expanded');
    const value = e?.detail?.value || null;
    if (!isTrusted) { return; }
    this.restaurantsService.setSelectedRestaurantId(value);
    this.restaurantsService.setSelectedBranchId(null);
  }

  public onBranchChange(event: Event) {
    const e = event as CustomEvent;
    const isTrusted = (e?.currentTarget as any)?.classList?.contains('select-expanded');
    const value = e?.detail?.value || null;
    if (!isTrusted) { return; }
    this.restaurantsService.setSelectedBranchId(value);
  }

  public ngOnInit() {
    this.init();
  }

  public init() {
    this.setUserProfile();
    this.setRestaurants();
    this.setBranches();
  }

  private setUserProfile() {
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        if(userProfile){
          this.userProfile = userProfile;
          const userRoles = this.userProfile?.roles;
          this.isAdmin = userRoles.includes(Role.ADMINS);
          this.isRestaurantAdmin = userRoles.includes(Role.ADMINS);
          this.isCustomer = userRoles.includes(Role.USERS);
        }
      }
    });
  }

  private setRestaurants() {
    this.restaurants = [];
    this.restaurantsService.getRestaurants().subscribe({
      next: (restaurants: RestaurantEntity[]) => {
        this.restaurants = restaurants;
        this.setSelectedRestaurantId();
      }
    });
  }

  private setSelectedRestaurantId() {
    this.restaurantId = null;
    this.restaurantsService.getSelectedRestaurantId().subscribe({
      next: (restaurantId: any) => {
        setTimeout(() => {
          this.restaurantId = restaurantId;
          const selectedResto = this.restaurants?.find(resto => resto?.restaurantId === this.restaurantId);
          this.restaurantsService.setSelectedRestaurant(selectedResto);
          this.restaurantIdChange.emit(restaurantId);
          this.emitFiltersValueChange();
        }, 100);
      }
    });
  }

  private setBranches() {
    this.branches = [];
    this.restaurantsService.getBranches().subscribe({
      next: (branches: RestaurantBranchEntity[]) => {
        this.branches = branches;
        this.setSelectedBranchId();
      }
    });
  }

  private setSelectedBranchId() {
    this.restaurantBranchId = null;
    this.restaurantsService.getSelectedBranchId().subscribe({
      next: (branchId: any) => {
        setTimeout(() => {
          this.restaurantBranchId = branchId;
          this.restaurantBranchIdChange.emit(branchId);
          this.emitFiltersValueChange();
        }, 100);
      }
    });
  }

  private emitFiltersValueChange() {
    this.filtersValueChange.emit({
      restaurantId: this.restaurantId,
      restaurantBranchId: this.restaurantBranchId,
    });
  }

}
