import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UtilityService } from '../../services/utilities/utilities.service';
import { iRatingValue } from './star-rating/star-rating.component';
import { OrderFeedbackService } from 'src/app/swagger/api/order-feedback.service';
import { iOrderReviewDto } from 'src/app/swagger/model/menuModel';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  public static readonly id = 'order-review-modal';

  @Input() public restaurantId: string = null;
  @Input() public restaurantBranchId: string = null;
  @Input() public orderNumber: number = 0;
  @Input() public isCustomer: boolean = false;

  public overallRating: number = 0;
  public foodRating: number = 0;
  public serviceRating: number = 0;
  public ambianceRating: number = 0;

  public reviews: any[] = [];
  public isDisabled: boolean = false;

  public reviewForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{10}$'),
    ]),
    comments: new FormControl('', []),
  });

  constructor(
    private modal: ModalController,
    private utilService: UtilityService,
    private orderReviewService: OrderFeedbackService 
  ) {}

  ngOnInit() {
    if(this.restaurantId && this.restaurantBranchId) {
      this.orderReviewService.getAllReviews(this.restaurantId, this.restaurantBranchId, this.isCustomer).subscribe({
        next: (resp: any) => {
          this.reviews = resp?.item;
          this.fillUpForm();
        }
      });
    }

  }

  private fillUpForm(): void {

    const formValue = this.reviews?.find(review => review?.orderNumber === this.orderNumber);
   
    if(formValue) {
      this.reviewForm.patchValue({
        name: formValue?.name,
        phoneNumber: formValue?.phoneNumber,
        comments: formValue?.comments,
      });
      this.ambianceRating = formValue?.ambianceRating;
      this.serviceRating = formValue?.serviceRating;
      this.foodRating = formValue?.foodRating;
      this.overallRating = formValue?.overallRating;
      this.isDisabled = true;
      this.reviewForm.disable();
    }

    if(!formValue || formValue === undefined){
      this.isDisabled = false;
      this.reviewForm.enable();
    }

  }

  public onRatingChange(value: iRatingValue) {
    if (value?.fieldName === 'ambiance') {
      this.ambianceRating = value?.rating;
    } else if (value?.fieldName === 'food') {
      this.foodRating = value?.rating;
    } else if (value?.fieldName === 'service') {
      this.serviceRating = value?.rating;
    } else {
      this.overallRating = value?.rating;
    }
  }

  public async onSave(): Promise<void> {
    if (this.reviewForm?.invalid) {
      this.utilService.showAlert('Please fill all the required fields', 'Invalid Input','invalid');
      return;
    }

    if (this.validateData() && this.reviewForm?.valid) {
      const payload: iOrderReviewDto = {
        name: this.reviewForm?.get('name')?.value ?? '',
        phoneNumber: this.reviewForm?.get('phoneNumber')?.value ?? '',
        orderNumber: this.orderNumber,
        overallRating: this.overallRating ?? 0,
        foodRating: this.foodRating ?? 0,
        serviceRating: this.serviceRating ?? 0,
        ambianceRating: this.ambianceRating ?? 0,
        comments: this.reviewForm?.get('comments')?.value ?? '',
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
      };
      this.orderReviewService.saveOrderReview(payload, this.isCustomer).subscribe({
        next: (resp: any) => {
          this.utilService.showAlert(
            `Thank you, ${resp.item?.name}, for your valuable feedback!`, 
            'Review Submission Successful', 
            'valid'
          );
          this.modal.dismiss();
        }
      })
    }
  }

  public validateData(): boolean {
    const formValue = this.reviewForm.value;

    if (formValue.name.trim() === '') {
      return false;
    }

    if (!/^[0-9]{10}$/.test(formValue.phoneNumber)) {
      return false;
    }

    return true;
  }

  public onClose(): void {
    this.modal.dismiss('', '', FeedbackFormComponent.id);
  }
}
