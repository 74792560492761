import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressModel, ProfileModel, ProfileRestControllerForUsersService, SmartDineResponseProfileModel } from 'src/app/swagger';
import { Role } from 'src/app/common/enums/role.enum';
import { UserProfileService } from 'src/app/common/services/user-profile/user-profile.service';
import { v4 as uuidv4 } from 'uuid';
import { GeoLocation } from 'src/app/swagger/model/geoLocation';
import { AddressComponent } from 'src/app/common/components/maps/address/address.component';

interface RoleItem {
  id: string;
  name: string;
}

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})

export class UserInfoComponent implements OnInit {
  public static readonly id = "user-info-modal";

  @ViewChild('map')
  mapRef: ElementRef<HTMLElement>;
  
  public addressId: string | null = null;

  @Input() 
  public formType: string | null = null;

  @Input() 
  public isViewAddress: boolean | false = false;

  @Input() 
  public modalTitle: string | null = null;

  @Input()
  public userProfile: ProfileModel | null = null;

  @Input()
  public editAddress: AddressModel | null = null;

  public userForm: FormGroup | null = null;
  public userAddressForm: FormGroup | null = null;
  public roles: RoleItem[] = [];
  public isAdmin = false;
  public userAddress: AddressModel[];

  constructor(
    private modalController: ModalController,
    private profileRestControllerForUsersService: ProfileRestControllerForUsersService,
    private userProfileService: UserProfileService,
    private toastController: ToastController,
  ) {
    this.createForm();
    this.createAddressForm();
   }

  private generateAddressId(): string {
    return uuidv4();
  }

  private init(){
    this.addressId = this.editAddress ? this.editAddress.addressId : this.generateAddressId();
    this.isAdmin = this.userProfile.roles.includes(Role.ADMINS);
    this.userForm.patchValue(this.userProfile);
    this.userAddressForm.patchValue(this.editAddress);
  }

  ngOnInit() {
    this.init();
    this.setRoles();
  }

  private createForm() {
    this.userForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      roles: new FormControl([], [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
    });
  }

  public createAddressForm() {
    this.userAddressForm = new FormGroup({
      addressId: new FormControl(this.addressId),
      addressName: new FormControl('', []),
      houseNumber: new FormControl('', []),
      buildingName: new FormControl('', []),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      userId: new FormControl(this.userProfile?.userId, []),
      district: new FormControl('', []),
      landmark: new FormControl('', []),
      state: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      countryCode: new FormControl('IN', []),
      pinCode: new FormControl('', [Validators.required]),
      location: new FormControl({latitude: 0, longitude: 0} as GeoLocation, []),
      addressType: new FormControl(AddressModel.AddressTypeEnum.HOME, []),
      addressTypeClassification: new FormControl(AddressModel.AddressTypeClassificationEnum.SHIPPING, [])
    });
  }
  
  public dismissPopup() {
    this.modalController.dismiss();
  }

  public saveDetails() {
    if(this.userForm && this.formType === "PROFILE"){
      this.userForm.markAllAsTouched();
      if(this.userForm.get('firstName')?.value?.trim() === '' || 
      this.userForm.get('lastName')?.value?.trim() === '' || 
      this.userForm.get('phoneNumber').value?.trim() === '' ) {
        this.showToast('Please fill all required details');
        return;
      }

      else if (this.userForm.valid) {
        this.saveUserProfile();
      }
    }else{
      this.userAddressForm.markAllAsTouched();
      if(this.userAddressForm.valid)
        this.saveUserAddress();
    }
  }

  private setRoles() {
    const roles = Object.keys(Role);
    this.roles = roles.map(role => ({
      id: Role[role],
      name: role,
    }));
  }

  private saveUserProfile() {
    let userProfile = this.userProfile;
    const formValue = this.userForm.value;
    Object.assign(userProfile, formValue);
    userProfile['addressModels'] = this.userProfile.addressModels;
    this.profileRestControllerForUsersService.updateLoggedInUserProfile(userProfile).subscribe({
      next: (res: SmartDineResponseProfileModel) => {

        this.userProfileService.setUserProfile(res.item);
        this.userForm.patchValue(res.item);
        this.showToast('Profile Edited successfully!');
       this.modalController.dismiss();
      },
      error: (err) => {
        console.error('Unable to save the user profile', err);
      }
    });
  }

  private saveUserAddress() {
    const addressTypeValue = this.userAddressForm.get('addressType').value.toUpperCase();
    this.userAddressForm.get('addressType').setValue(addressTypeValue);
    const userProfile = { ...this.userProfile };
    const formValue = this.userAddressForm.value;
    const newAddress = { ...formValue };
    const addressIndex = userProfile.addressModels.findIndex(
      address => address.addressId === newAddress.addressId
    );
    if (addressIndex !== -1) {
      userProfile.addressModels[addressIndex] = newAddress;
    } else {
      userProfile.addressModels.push(newAddress);
    }
    userProfile['firstName'] = this.userProfile?.firstName;
    userProfile['lastName'] = this.userProfile?.lastName;
    userProfile['phoneNumber'] = this.userProfile?.phoneNumber;
    userProfile['roles'] = this.userProfile?.roles;
    this.profileRestControllerForUsersService.updateLoggedInUserProfile(userProfile).subscribe({
      next: (res: SmartDineResponseProfileModel) => {

        this.userProfileService.setUserProfile(res.item);
        this.userAddressForm.patchValue(res.item);
        this.showToast('Address Saved successfully!');
        this.modalController.dismiss(res.item , '', UserInfoComponent.id);
      },
      error: (err) => {
        console.error('Unable to save the user profile', err);
      }
    });
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  showMap(locationFormControl: FormControl) {
    this.showMapPopup(locationFormControl);
  }

  public async showMapPopup(locationFormControl: FormControl) {
    const geolocation: GeoLocation = locationFormControl.value;
    const model = await this.modalController.create({
      component: AddressComponent,
      id: AddressComponent.id,
      componentProps: {
        location: geolocation
      }
    });
   model.onDidDismiss().then((selLocation) => {
     locationFormControl.setValue({latitude: selLocation.data.location.lat, longitude: selLocation.data.location.lng});
    });
    await model.present();
  }
}
