import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { MenuItemEntity, MenuItemRestControllerService, MenuRestControllerService, SmartDineResponseMenuItemEntity } from 'src/app/swagger';
import { MenuItemMapEntity } from 'src/app/swagger';
import { ItemUpsertionComponent } from '../item-upsertion/item-upsertion.component';


@Component({
  selector: 'app-sd-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemsComponent implements OnInit, OnChanges {

  @Input()
  public menuId: string | null = null;

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  public items: MenuItemEntity[] = [];

  @Input()
  set menuItemMappings(mappings: MenuItemMapEntity[]) {
    if (!mappings) { return; }
    this.items = mappings.map(mapping => mapping.menuItemEntity);
  }

  @Input() enableToolbar = true;

  @Output() refresh = new EventEmitter();

  private selectedIndex = -1;

  public filteredItems = null;

  constructor(
    private router: Router,
    private menuRestControllerService: MenuRestControllerService,
    private menuItemRestControllerService: MenuItemRestControllerService,
    private modalController: ModalController,
    private toastController: ToastController,
    private alertController: AlertController,
  ) { }

  public ngOnInit() {
    this.init();
  }

  public ngOnChanges(_changes: SimpleChanges): void {
    this.init();
  }

  public onMenuItemClick(menuItem: MenuItemEntity) {
    const extras: NavigationExtras = { queryParams: {} };
    if (this.menuId) {
      extras.queryParams.menuId = this.menuId;
    }
    if (this.restaurantId) {
      this.router.navigate(['/admin/restaurants', this.restaurantId, 'items', menuItem.itemId], extras);
    } else {
      this.router.navigate(['/admin/items', menuItem.itemId], extras);
    }
  }

  public onNewClick() {
    this.showMenuCreationPopup();
    // const extras: NavigationExtras = { queryParams: {} };
    // if (this.menuId) {
    //   extras.queryParams['menuId'] = this.menuId;
    // }
    // if (this.restaurantId) {
    //   this.router.navigate(['/admin/restaurants', this.restaurantId, 'items', 'new'], extras);
    // } else {
    //   this.router.navigate(['/admin/items/new'], extras);
    // }
  }

  public onEditClick(event: MouseEvent, item: MenuItemEntity) {
    event.stopPropagation();
    this.showMenuCreationPopup(item);
  }

  public onDeleteClick(event: MouseEvent, item: MenuItemEntity, index: number) {
    this.selectedIndex = index;
    event.stopPropagation();
    this.onDelete();
  }

  private setMenuEntity(menuId: string) {
    this.menuRestControllerService.getMenu(menuId).subscribe({
      next: (res) => {
        this.items.push(res.item);
      },
      error: (err) => {
        console.error('Unable to get menu details!', err);
      }
    });
  }

  public async showMenuCreationPopup(item: MenuItemEntity = null) {
    let itemId = null;
    if (item) {
      itemId = item.itemId;
    }
    const modal = await this.modalController.create({
      id: ItemUpsertionComponent.id,
      component: ItemUpsertionComponent,
      componentProps: {
        itemId,
        menuId: this.menuId,
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
      }
    });
    modal.onDidDismiss().then((res) => {
      if (!res || !res.data) { return; }
      if (res.data.createdItem) {
        this.items.push(res.data.createdItem);
      }
      if (res.data.updatedItem) {
        const updatedItem = res.data.updatedItem as MenuItemEntity;
        const index = this.items.findIndex((i) => i.itemId === updatedItem.itemId);
        this.items[index] = updatedItem;
      }
    });
    await modal.present();
  }

  public async onDelete() {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: `Please confirm the deletion of below mentioned menu item.<br><b>${this.items[this.selectedIndex].name}<b>`,
      buttons: [
        'Cancel',
        {
          text: 'Delete',
          handler: () => {
            this.deleteItem();
          }
        }
      ]
    });
    await alert.present();
  }

  private init() {
    this.setItems();
  }

  private setItems() {
    this.items = [];
    this.menuItemRestControllerService.getMenuItems(this.restaurantId, this.restaurantBranchId).subscribe({
      next: (res: SmartDineResponseMenuItemEntity) => {
        this.items = res.items;
        this.filteredItems = this.items;
      },
      error: (err) => {
        console.error('Unable to get menu items!', err);
      }
    });
  }

  private deleteItem() {
    const item = this.items[this.selectedIndex];
    this.menuItemRestControllerService.deleteMenuItem(item.itemId, this.restaurantId, this.restaurantBranchId).subscribe(
      (_: SmartDineResponseMenuItemEntity) => {
        this.items.splice(this.selectedIndex, 1);
        this.showToast('Item deleted successfully.');
      },
      (err) => {
        console.error('Unable to delete menu item!', err);
      }
    );
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  public filterItems(searchValue: string) {
    if(searchValue === "" || !searchValue.trim()){
      this.setItems();
    }
    this.items = this.filteredItems.filter(item =>{
      const lowerSearchValue = searchValue.toLowerCase();
      return (
        item.name.toLowerCase().includes(lowerSearchValue) ||
        item.tags.some(tag => tag.toLowerCase().includes(lowerSearchValue))
      );
    });
  }

}
