import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { LoaderService } from '../../../common/services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  private session: CognitoUserSession | null = null;

  public form: FormGroup;

  public pwdChangeForm: FormGroup;

  public codeVerificationForm: FormGroup;

  private currentUser: CognitoUser;
  public errorMessage: string = '';
  private timer: NodeJS.Timeout;

  public showLoginForm: boolean = true;

  public showPasswordChangeForm: boolean = false;

  public showCodeConfirmationForm: boolean = false;

  public hidePassword: boolean = true;
  public iconName: string = 'call-outline';

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private authService: AuthService
  ) {
    this.createForm();
  }

  public createForm() {
    this.form = new FormGroup({
      countryCode: new FormControl('+91', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public changePwdAction() {
    this.pwdChangeForm.markAllAsTouched();
    if (this.pwdChangeForm.valid) {
      if (
        this.pwdChangeForm.get('newPassword').value ==
        this.pwdChangeForm.get('reenterNewPassword').value
      ) {
        this.currentUser.completeNewPasswordChallenge(
          this.pwdChangeForm.get('newPassword').value,
          { name: this.currentUser.getUsername() },
          {
            onSuccess: async (result) => {
              this.setErrorMessage('Password needs to be changed');
              this.pwdChangeForm.reset();
              this.loaderService.hideLoader();

              await this.authService.refresh();
              this.router.navigateByUrl('/', { replaceUrl: true });
            },
            onFailure: (err) => {
              this.setErrorMessage('Error occured' + err);
            },
          }
        );
      }
      {
        this.setErrorMessage('Passwords are not same');
      }
    } else {
      this.setErrorMessage('Please check entered data');
    }
  }

  public loginAction(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      this.setErrorMessage('Username/Password cannot be blank!');
      return;
    }

    const { countryCode, username, password } = this.form.value;

    if (this.isEmail(username)) {
      if (!username.includes('@') || !username.includes('.')) {
        this.setErrorMessage('Please enter a valid email address!');
        return;
      }
      this.signIn(username, password);
    } else if (this.isMobile(username)) {
      if (!/^\d{10}$/.test(username)) {
        this.setErrorMessage('Please enter a valid mobile number!');
        return;
      }
      this.signIn(countryCode + username, password);
    } else {
      this.setErrorMessage('Please enter a valid username');
    }
  }

  public ngOnInit() {
    this.setSession();
    this.form.get('username').valueChanges.subscribe((value) => {
      this.adjustCountryCodeVisibility(value);
      this.getIconName();
    });
  }

  private setSession() {
    this.session = null;
    this.authService.getSession().subscribe({
      next: (session: CognitoUserSession | null) => {
        if (session) {
          this.router.navigateByUrl('/', { replaceUrl: true });
          this.session = session;
        }
      },
    });
  }

  private isEmail(value: string): boolean {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  }

  private isMobile(value: string): boolean {
    const mobilePattern = /^[0-9]{10,15}$/;
    return mobilePattern.test(value);
  }

  public isCountryCodeHidden(): boolean {
    const username = this.form.get('username')?.value;
    return /[a-zA-Z]/.test(username) || this.isEmail(username);
  }

  private getIconName() {
    const username = this.form.get('username')?.value;
    this.iconName =
      this.isEmail(username) || /[a-zA-Z]/.test(username)
        ? 'mail-outline'
        : 'call-outline';
  }

  private adjustCountryCodeVisibility(value: string): void {
    if (/[a-zA-Z]/.test(value) || this.isEmail(value)) {
      this.form.get('countryCode')?.clearValidators();
    } else {
      this.form.get('countryCode')?.setValidators([Validators.required]);
    }
    this.form.get('countryCode')?.updateValueAndValidity();
  }

  public async signIn(uname: string, pwd: string) {
    this.loaderService.showLoader();
    this.setErrorMessage('');
    try {
      this.currentUser = await Auth.signIn(uname, pwd);
      console.info('User:', this.currentUser);

      this.form.reset();
      this.loaderService.hideLoader();

      if (this.currentUser['challengeName'] === 'NEW_PASSWORD_REQUIRED') {
        this.setErrorMessage('Password needs to be changed');
        this.showLoginForm = false;
        this.showPasswordChangeForm = true;
        this.createPwdChangeForm();
      } else {
        await this.authService.refresh();
        this.router.navigateByUrl('/', { replaceUrl: true });
      }
    } catch (err) {
      this.form.get('password').setValue('');
      this.loaderService.hideLoader();
      let errorMessage = 'Unable to process your request. Try again later!';
      if (err?.code === 'UserNotFoundException') {
        errorMessage = 'Invalid Credentials!';
      } else if (err?.code === 'UserNotConfirmedException') {
        errorMessage = err.message;
        this.handleCodeVerificationFlow(uname);
      } else if (err?.message) {
        errorMessage = err.message;
      }
      this.setErrorMessage(errorMessage);
    }
  }
  public createPwdChangeForm() {
    this.pwdChangeForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required]),
      reenterNewPassword: new FormControl('', [Validators.required]),
    });
  }

  public createCodeVerificationForm(uname: string) {
    this.codeVerificationForm = new FormGroup({
      uname: new FormControl(uname, [Validators.required]),
      verificationCode: new FormControl('', [Validators.required]),
    });
  }

  public handleCodeVerificationFlow(uname: string) {
    Auth.resendSignUp(uname).then((response) => {
      this.setErrorMessage(
        'Code is sent to ' +
          response.CodeDeliveryDetails.Destination +
          ' via ' +
          response.CodeDeliveryDetails.DeliveryMedium
      );
      this.showCodeConfirmationForm = true;
      this.showLoginForm = false;
      this.createCodeVerificationForm(uname);
    });
  }

  public codeVerificationAction() {
    this.codeVerificationForm.markAllAsTouched();
    if (this.codeVerificationForm.valid) {
      Auth.confirmSignUp(
        this.codeVerificationForm.get('uname').value,
        this.codeVerificationForm.get('verificationCode').value
      )
        .then((result) => {
          this.router.navigateByUrl('/', { replaceUrl: true });
        })
        .catch((error) => {
          this.setErrorMessage('error' + error);
        });
    } else {
      this.setErrorMessage('Please enter verification code');
    }
  }

  public setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  public togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }
}
