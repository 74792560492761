/**
 * SmartdineNow API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

 import { Inject, Injectable, Optional } from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent,} from '@angular/common/http';
 
 import { Observable } from 'rxjs';
 import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
 import { Configuration } from '../configuration';
 import { iCommonApiResponse, iTableBookingEntity,} from '../model/table-booking/table-booking.interface';
 import { CustomHttpUrlEncodingCodec } from '../encoder';
import { iOrderReviewDto } from '../model/menuModel';
 

@Injectable({
  providedIn: 'root'
})
export class OrderFeedbackService {

  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  private getHeaderConfig(): HttpHeaders {
    let headers = this.defaultHeaders;

    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return headers;
  }

  public saveOrderReview(body: iOrderReviewDto, isCustomer: boolean, observe?: 'body', reportProgress?: boolean): Observable<iCommonApiResponse>;
  public saveOrderReview(body: iOrderReviewDto, isCustomer: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<iCommonApiResponse>>;
  public saveOrderReview(body: iOrderReviewDto, isCustomer: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<iCommonApiResponse>>;
  public saveOrderReview(body: iOrderReviewDto, isCustomer: boolean, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    
    if (body === null || body === undefined) {
      throw new Error( 'Required parameter body was null or undefined when calling initiatePayment.');
    }

    const headers = this.getHeaderConfig();
    const apiEndpoint = isCustomer ? `${this.basePath}/api/user/review`: `${this.basePath}/api/admin/review`;
    return this.httpClient.request<iCommonApiResponse>(
      'post',
      apiEndpoint,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public getAllReviews(restaurantId?: string, restaurantBranchId?: string, isCustomer?: boolean, reportProgress?: boolean): Observable<iCommonApiResponse> {

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (restaurantId !== undefined && restaurantId !== null) {
      queryParameters = queryParameters.set('restaurantId', <any>restaurantId);
    }
    if (restaurantBranchId !== undefined && restaurantBranchId !== null) {
      queryParameters = queryParameters.set(
        'restaurantBranchId',
        <any>restaurantBranchId
      );
    }

    
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const apiEndpoint = isCustomer ? `${this.basePath}/api/user/review`: `${this.basePath}/api/admin/review`;

    return this.httpClient.request<iCommonApiResponse>(
      'get',
      apiEndpoint,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: reportProgress,
      }
    );
  }


}
