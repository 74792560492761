import { LoaderService } from './common/services/loader/loader.service';
import { Component, OnInit, NgZone, ViewChild, OnDestroy } from '@angular/core';
import { ProfileModel } from './swagger';
import { UserProfileService } from './common/services/user-profile/user-profile.service';
import { Role } from './common/enums/role.enum';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from './core/services/auth/auth.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { publicRoutes } from './app-routing.module';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AlertController, IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { ModalService } from './common/services/modal/modal.service';
import { Subscription } from 'rxjs';
import { MessageService } from './common/services/message/message.service';
import { RedirectUserService } from './swagger/api/redirectUser.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  private session: CognitoUserSession | null = null;
  private userProfile: ProfileModel | null = null;
  private currentModalId: string | null = null;
  modalSubscription: Subscription;

  private exitUrls: string[] = ['/customer/home', '/resto-admin/home', '/login', '/sign-up', '/password-recovery', '/privacy', '/home'];

  private timerIntervalId!: NodeJS.Timeout;

  constructor(
    private userProfileService: UserProfileService,
    private location: Location,
    private router: Router,
    private loaderService: LoaderService,
    private authService: AuthService, private zone: NgZone, private platform: Platform,
    private alertController: AlertController,
    private modalController: ModalController,
    private modalService: ModalService,
    private messageService: MessageService,
    private redirectUserService: RedirectUserService
  ) {

    this.platform.ready().then(async () => {
      this.messageService.showMessage('');
      SplashScreen.hide();
    });


    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      let showExitButton = false;

      this.exitUrls.forEach((exitUrl) => {
        if (this.location.isCurrentPathEqualTo(exitUrl)) {
          showExitButton = true
        }
      });

      if (showExitButton) {
        // Show Exit Alert!
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        if (this.currentModalId == null) {
          this.location.back();
        } else {
          this.modalController.dismiss({}, '', this.currentModalId);
          this.modalService.changeCurrentModalId(null);
        }
      }
    });
  }

  showExitConfirm() {
    this.alertController.create({
      header: 'SmartdineNow',
      message: 'Do you want to close the app?',
      backdropDismiss: false,
      buttons: [{
        text: 'Stay',
        role: 'cancel',
        handler: () => {
          console.log('Application exit prevented!');
        }
      }, {
        text: 'Exit',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    })
      .then(alert => {
        alert.present();
      });
  }

  public async ngOnInit() {
    await this.loaderService.init();
    this.init();
  }

  public ngOnDestroy() {
    clearInterval(this.timerIntervalId);
    this.modalSubscription.unsubscribe();
  }

  public init() {
    this.setSession();
    this.initializeApp();

    this.modalSubscription = this.modalService.currentModal.subscribe(modalId => {
      this.currentModalId = modalId;
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split("smartdinenow.com").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  private setSession() {
    this.session = null;
    this.authService.getSession().subscribe({
      next: (session: CognitoUserSession | null) => {
        this.session = session;
        this.setUserProfile();
      }
    });

    // forcing the session update every 30 seconds..
    //this.timerIntervalId = setInterval(() => {
    //  this.authService.getSession(true);
    //}, 30000);
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile(true).subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
        const redirectUrl = this.redirectUserService.getRedirectUrl();
        if (redirectUrl && this.userProfile) {
          const decodedUrl = decodeURIComponent(redirectUrl);
          this.router.navigateByUrl(decodedUrl);
        }
        else if (!redirectUrl && this.userProfile) {
          this.loadDefaultSecurePage();
        } else {
          this.loadDefaultPublicPage();
        }
        SplashScreen.hide();
      },
      error: (errorResponse) => {
        console.error('Unable to get the User-Profile! Please contact admin');
      }
    });
  }

  /**
   * Loads Default Public Page for the Guest Users.
   */
  private loadDefaultPublicPage() {
    const currentPath = this.location.path();
    if (currentPath) {
      // Allowing public routes to be loaded..
      let currentRoute:any;
      currentRoute = currentPath.split('/')[1];
      currentRoute = currentRoute.split('?')[0];
      const publicRoutePaths = publicRoutes.map(e => e.path);
      if (publicRoutePaths.includes(currentRoute) || currentRoute == 'customer') { return; }
    }
    SplashScreen.hide();
  }

  /**
   * Loads Default Secure Page for Logged In Users. 
   */
  private loadDefaultSecurePage() {
    const currentPath = this.location.path();

    if (!this.isSecurePage(currentPath)) {
      const userRoles = (this.userProfile) ? this.userProfile.roles : '';
  
      if (userRoles.includes(Role.ADMINS)) {
        this.router.navigateByUrl('/admin/home');
      } else if (userRoles.includes(Role.RESTO_ADMINS)) {
        this.router.navigateByUrl('/resto-admin/home');
      } else if (userRoles.includes(Role.USERS)) {
        this.router.navigateByUrl('/customer/home');
      } else {
        console.warn('User missing roles!');
        // TODO: UI Display!
        this.router.navigateByUrl('/customer');
      }
    }
  }
  
  private isSecurePage(path: string): boolean {
    return path.startsWith('/admin/') || path.startsWith('/resto-admin/') || path.startsWith('/customer/');
  }

}
