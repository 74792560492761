import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface iRatingValue {
  rating?: number;
  fieldName?: string;
}

@Component({
  selector: 'review-star',
  template: `<div class="star-rating">
    <ion-icon
      *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
      name="{{ i < rating ? 'star' : 'star-outline' }}"
      (click)="toggleRating(i + 1)"
    ></ion-icon>
  </div> `,
  styles: [
    `
      .star-rating {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ion-icon {
          font-size: 30px;
          color: #95D2B3;
          cursor: pointer;
        }
      }
    `,
  ],
})
export class StarRatingComponent implements OnInit {
  @Input() rating: number = 0;
  @Input() fieldName: string = '';
  @Input() isDisabled: boolean = false;

  @Output() ratingChange: EventEmitter<iRatingValue> =
    new EventEmitter<iRatingValue>();

  public toggleRating(value: number) {
    if(!this.isDisabled) {
      this.rating = this.rating === value ? value - 1 : value;
      this.ratingChange.emit({ rating: this.rating, fieldName: this.fieldName });
    }
  }

  ngOnInit(): void {}
}
