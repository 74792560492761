import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import {
  ProfileModel,
  QRCodeModel,
  RestaurantBranchTableEntity,
  RestaurantBranchTableRestControllerService,
  RestaurantQrCodeRestControllerService,
  SmartDineResponseQRCodeModel,
  SmartDineResponseRestaurantBranchTableEntity,
} from 'src/app/swagger';
import { UserProfileService } from '../../services/user-profile/user-profile.service';
import { QrInfoComponent } from '../qr-info/qr-info.component';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss'],
})
export class TablesComponent implements OnInit {

  public static readonly id = 'tables-modal';

  @Input()
  public restaurantId: string | null = null;

  @Input()
  public restaurantBranchId: string | null = null;

  public form: FormGroup;
  public tables: RestaurantBranchTableEntity[] = [];
  private userProfile: ProfileModel | null = null;

  public qrCodes: QRCodeModel[] = [];

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private restaurantBranchTableRestControllerService: RestaurantBranchTableRestControllerService,
    private userProfileService: UserProfileService,
    private restaurantQrCodeRestControllerService: RestaurantQrCodeRestControllerService,
    private toastController:ToastController,
  ) { }

  get tableControls() {
    return this.form.get('tables') as FormArray;
  }

  public ngOnInit() {
    this.setUserProfile();
    this.setTables();
  }

  public onSaveClick() {
    this.form.markAllAsTouched();
    if (this.form.invalid) { return; }
    this.tables.forEach((table, index) => {
      const control = this.tableControls.at(index);
      if (control.pristine && table.tableId) { return; }
      const details = {
        tableId: null,
        restaurantId: this.restaurantId,
        restaurantBranchId: this.restaurantBranchId,
        creator: this.userProfile.userId,
        createdAt: new Date(),
      } as RestaurantBranchTableEntity;
      Object.assign(details, control.value);
      const existingDetails = this.tables[index];
      if (existingDetails?.tableId) {
        details.tableId = existingDetails.tableId;
        details.creator = existingDetails.creator || this.userProfile.userId;
        details.createdAt = existingDetails.createdAt || new Date();
        details.modifier = this.userProfile.userId;
        details.modifiedAt = new Date();
      }
      this.saveTable(details, index);
    });
  }

  public onCloseClick() {
    this.modalController.dismiss('', '', TablesComponent.id);
  }

  public async onNewClick() {
    const alert = await this.alertController.create({
      header: 'Table or Room Code',
      inputs: [
        {
          name: 'tableCode',
          type: 'text',
          placeholder: 'Enter Table or Room Code'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Add',
          role: 'submit',
          handler: () => { }
        },
      ]
    });
    alert.onDidDismiss().then((res) => {
      const role = res?.role || '';
      const tableCode = res?.data?.values?.tableCode || null;
      if (role === 'submit' && tableCode) {
        this.updateForm(tableCode);
      }
    });
    await alert.present();
  }

  public onQrClick(_event: Event, table: { tableId?: string; }) {
    if (!table.tableId) { return; }
    this.getQrCode(table.tableId).subscribe({
      next: (res) => {
        const qrInfo = res.item;
        this.showQrPopup(qrInfo);
      },
      error: (err) => {
        console.error('Unable to retrieve Qr codes!', err);
      }
    });
  }

  public createForm(value?: any) {
    this.form = null;
    const tableControls = this.tables.map((table) => {
      return new FormGroup({
        tableCode: new FormControl(table.tableCode, Validators.required),
        capacity: new FormControl(table.capacity, [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]+$/)]),
        captain: new FormControl(table.captain, []),
      });
    });
    this.form = new FormGroup({ tables: new FormArray(tableControls) });
    if (value) {
      this.form.patchValue(value);
    }
  }

  public updateForm(tableCode: string) {
    this.tables.push({ tableCode, capacity: 0 });
    const value = this.form.value;
    this.createForm(value);
  }

  private setUserProfile() {
    this.userProfile = null;
    this.userProfileService.getUserProfile().subscribe({
      next: (userProfile: ProfileModel) => {
        this.userProfile = userProfile;
      }
    });
  }

  private setTables() {
    if (!this.restaurantId || !this.restaurantBranchId) { return; }
    this.restaurantBranchTableRestControllerService.getRestaurantBranchTables1(
      this.restaurantId, this.restaurantBranchId
    ).subscribe({
      next: (res: SmartDineResponseRestaurantBranchTableEntity) => {
        this.tables = res.items;
        this.createForm();
      },
      error: (err) => {
        console.error('Unable to get tables info!');
      }
    });
  }

  private saveTable(table: RestaurantBranchTableEntity, position: number) {
    this.restaurantBranchTableRestControllerService.createRestaurantBranchTable(
      table, this.restaurantId, this.restaurantBranchId
    ).subscribe({
      next: (res) => {
        
        this.tables[position] = res.item;
        this.tableControls.at(position).reset(this.tables[position]);
      },
      error: (err) => {
        console.error('Unable to save the table info!');
      }
    });
  }

  public async showQrPopup(qrInfo: QRCodeModel) {
    const model = await this.modalController.create({
      component: QrInfoComponent,
      id: QrInfoComponent.id,
      componentProps: { qrInfo }
    });
    await model.present();
  }

  private getQrCode(tableId: string) {
    if (!this.restaurantId || !this.restaurantBranchId) { return; }
    const parsedUrl = new URL(window.location.href);
    let domainHostName = null;
    if (parsedUrl) {
      domainHostName = parsedUrl.hostname;
    }
    return this.restaurantQrCodeRestControllerService.getQRCodes(
      this.restaurantId, this.restaurantBranchId, tableId, domainHostName
    ).pipe(
      map((res: SmartDineResponseQRCodeModel) => {
        return { item: res.items[2] };
      })
    );
  }

  public onDeleteTable(_event:Event,tableInfo:RestaurantBranchTableEntity,index:number){
    _event.stopPropagation();
    this.removeTable(tableInfo,index);
  }

  private removeTable(table:RestaurantBranchTableEntity,index:number) {
    this.restaurantBranchTableRestControllerService.deleteRestaurantBranchTable(table.restaurantId,this.restaurantBranchId,table.tableId).subscribe({
      next:()=>{
        this.tables.splice(index, 1);
        this.showToast('Table deleted successfully.');
      },
      error:(err)=>{
        this.showToast('Unable to Delete the table');
      }
    })
  }

  private async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

}
